import React from "react";
import { WebsiteHomepage } from "~slices";

const BuildHomepage = () => (
  <>
    <WebsiteHomepage />
  </>
);

export default BuildHomepage;
